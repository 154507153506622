<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-8">
					<a-radio-group :value="filters.status" @change="handleStatusChange" style="padding: 0">
						<a-radio-button value="0"> Solicitudes </a-radio-button>
						<a-radio-button value="5"> Enviadas </a-radio-button>
						<a-radio-button value="2"> Autorizado / En preparación </a-radio-button>
						<a-radio-button value="3"> En reparto </a-radio-button>
						<a-radio-button value="4"> Entregado </a-radio-button>
					</a-radio-group>
				</div>
				<div class="col-md-4 text-right">
					<a-button class="btn btn-info" icon="plus" @click="onAddRecord">Nueva cotización</a-button>
					<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-3">
					<b>Fecha</b>
					<a-range-picker style="width: 100%" @change="initModule" v-model="filters.default_date" :default-value="filters.default_date" :value-format="'YYYY-MM-DD'" :format="'DD-MM-YYYY'" />
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12">
					<a-table :columns="columns" :dataSource="searchText == '' ? impressmentList : impressmentListFiltered" rowKey="id" bordered>
						<div slot="fullName" slot-scope="record">
							<div style="font-size: 25px">#{{ record.id }}</div>
							<div>{{ record.fullName }}</div>
							<div>{{ moment(record.created_at).format('DD-MM-YYYY HH:mm') }} hrs.</div>
							<div>
								<small class="pointer" @click="getLogs(record.id)"><b>- LOGS -</b></small>
							</div>
						</div>
						<div slot="customer_name" slot-scope="record" class="text-left">
							<div class="text-uppercase"><i class="fa fa-user"></i> {{ record.customer_name }}</div>
							<div class="text-uppercase">
								<i class="fa fa-building"></i> {{ record.description || '- no definido -' }}
								<div><i class="fa fa-map"></i> <a :href="record.link" target="_blank" rel="noopener noreferrer">Ubicación Maps</a></div>
							</div>
							<hr class="m0" />
							<div v-if="evidenceMap[`E_${filters.status}`]">
								<small @click="openEvidenceModal(record.id)" style="margin-left: 15px" class="pointer"><b>Ver evidencias</b></small>
							</div>
						</div>
						<div slot="description" slot-scope="record" class="text-left">
							<div v-for="(element, index) in record.elements" :key="index">
								{{ `${element.quantity}` }}x {{ element.description }}
								<div style="font-size: 12px" class="pointer">
									<b @click="copySKU(element.sku)">SKU: {{ element.sku }}</b>
								</div>
							</div>
						</div>
						<div slot="action" slot-scope="record">
							<!-- <a-tooltip placement="top" v-if="record.status == 5">
								<template slot="title">
									<span>Editar cotización</span>
								</template>
								<button class="btn btn-warning ant-btn-sm ant-btn-icon-only ml7" @click="changeStatus(record.id, 0)" >
									<a-icon type="left" />
								</button>
							</a-tooltip> -->
							<a-tooltip placement="top" v-if="record.status == 0 && getAllowed.includes(Number(filters.status))">
								<template slot="title">
									<span>Editar</span>
								</template>
								<button class="btn btn-info ant-btn-sm ant-btn-icon-only ml7" @click="onEditRecord(record.id)">
									<a-icon type="edit" />
								</button>
							</a-tooltip>
							<a-tooltip placement="top" v-if="record.status == 5 && getAllowed.includes(Number(filters.status))">
								<template slot="title">
									<span>Enviar por whatsapp</span>
								</template>
								<button class="btn btn-info ant-btn-sm ant-btn-icon-only ml7" @click="sendQuotation(record.id, 'whatsapp')">
									<a-icon type="message" />
								</button>
							</a-tooltip>
							<a-tooltip placement="top" v-if="[0, 5].includes(record.status) && getAllowed.includes(Number(filters.status))">
								<template slot="title">
									<span>Eliminar</span>
								</template>
								<button class="btn btn-danger ant-btn-sm ant-btn-icon-only ml7" @click="onCancelRecord(record.id)">
									<a-icon type="delete" />
								</button>
							</a-tooltip>
							<a-tooltip placement="top" v-if="[2, 3, 4].includes(record.status) && !record.pos_id">
								<template slot="title">
									<span>Asociar ticket</span>
								</template>
								<button class="btn btn-info ant-btn-sm ant-btn-icon-only ml7" @click="setTicket(record.id)">
									<a-icon type="file-text" />
								</button>
							</a-tooltip>
							<a-tooltip placement="top" v-if="record.status > 0">
								<template slot="title">
									<span>Imprimir</span>
								</template>
								<button class="btn btn-info ant-btn-sm ant-btn-icon-only ml7" @click="printQuotation(record.id)">
									<a-icon type="printer" />
								</button>
							</a-tooltip>
							<a-tooltip placement="top" v-if="record.status == 5 && getAllowed.includes(Number(filters.status))">
								<template slot="title">
									<span>Continuar proceso</span>
								</template>
								<button class="btn btn-success ant-btn-sm ant-btn-icon-only ml7" @click="confirmQuote(record.id)">
									<a-icon type="right" />
								</button>
							</a-tooltip>
							<a-tooltip placement="top" v-if="[2, 3].includes(record.status) && getAllowed.includes(Number(filters.status))">
								<template slot="title">
									<span>Continuar proceso</span>
								</template>
								<button class="btn btn-success ant-btn-sm ant-btn-icon-only ml7" @click="changeStatus(record.id, record.status)">
									<a-icon type="right" />
								</button>
							</a-tooltip>
							<div v-if="record.pos_id">
								<small>Ticket #{{ record.pos_id }}</small>
							</div>
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
		<a-modal :visible="modalQuotationView" title="Detalle de cotización" :closable="true" @cancel="closeQuotationModal" width="80%" v-if="modalQuotationView">
			<template slot="footer">
				<a-button key="back" @click="closeQuotationModal"> Cerrar </a-button>
			</template>
			<div class="col-md-12 pt10">
				<object :data="quotationBase64" type="application/pdf" width="100%" height="600px"></object>
			</div>
		</a-modal>
		<a-modal width="50%" :visible="modalQuotationFiles" :title="`Registro de evidencias - ${modalTitle}`" :closable="true" @cancel="closeQuotationFilesModal(false)">
			<template slot="footer">
				<a-button key="back" @click="closeQuotationFilesModal(false)" :loading="false"> Cancelar </a-button>
			</template>
			<impressmentFiles :id="impressment_id" :status="filters.status" @close="closeQuotationFilesModal(true)" />
		</a-modal>
		<a-modal width="50%" :visible="evidencesModalShow" title="Registro de evidencias" :footer="null" :closable="true" @cancel="evidencesModalShow = false">
			<div v-for="(text, key) in evidenceMap" :key="key">
				<h5 class="text-muted">{{ evidenceMap[key].at(-1) }}</h5>
				<div class="row" v-if="evidencesModal[key]">
					<div class="col-md-3" v-for="(img, index) in evidencesModal[key]" :key="index">
						<v-lazy-image :src="img.url" class="img-fluid"></v-lazy-image>
					</div>
				</div>
				<div class="row" v-else>
					<div class="col-md-12">Sin evidencia</div>
				</div>
				<div class="row">
					<div class="col-md-12"><hr /></div>
				</div>
			</div>
		</a-modal>
		<a-modal width="30%" :visible="logsView" title="LOGS" :footer="null" :closable="true" @cancel="logsView = false">
			<div>
				<span v-for="(element, index) in logs" :key="index"
					>{{ moment(element.created_at).format('DD-MM-YYYY HH:mm') }} hrs. - {{ element.action }}<br />
					{{ element.fullName }}
					<hr
				/></span>
			</div>
		</a-modal>
	</div>
</template>

<script>
//
import Vue from 'vue'
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import Swal from 'sweetalert2'
import _ from 'lodash'
import numeral from 'numeral'
import { mask } from 'vue-the-mask'
import utilities from '@/services/utilities'
import impressmentFiles from '@/components/impressmentFiles'
import VLazyImage from 'v-lazy-image/v2'
import copy from 'copy-to-clipboard'

export default {
	name: 'ImpressmentView',
	components: {
		impressmentFiles,
		VLazyImage,
	},
	directives: {
		mask,
	},
	computed: {
		...mapGetters('impressment', ['spinnerLoader', 'spinnerLoaderLabel', 'impressmentList', 'filters']),
		...mapGetters('customers', ['customersList']),
		...mapGetters('user', ['user']),
		userPermition() {
			// zem - 17
			// ismael - 1
			let allowedUserPermition = [1, 17]
			return !!allowedUserPermition.includes(this.user.user_id)
		},
		getImpressmentElements() {
			return this.actualRecord.impressmentElements.map((e, index) => {
				return {
					...e,
					index: index + 1,
				}
			})
		},
		getAllowed() {
			//
			if (this.user.user_id == 1) {
				return [0, 1, 2, 3, 4, 5]
			}
			return JSON.parse(utilities.objectValidate(this.user, 'impressment_allowed', '[]'))
		},
	},
	data() {
		return {
			searchText: '',
			modalTitle: '',
			impressment_id: '',
			impressmentListFiltered: [],
			evidencesModal: [],
			modalQuotationView: false,
			modalQuotationFiles: false,
			evidencesModalShow: false,
			quotationBase64: '',
			evidenceMap: {
				E_2: ['Evidencia autorización'],
				E_3: ['Evidencia autorización', 'Evidencia reparto'],
				E_4: ['Evidencia autorización', 'Evidencia reparto', 'Evidencia entrega'],
			},
			logs: [],
			logsView: false,
			columns: [
				{
					title: 'Solicitante',
					scopedSlots: { customRender: 'fullName' },
					align: 'center',
					width: '20%',
				},
				{
					title: 'Cliente/Dirección',
					scopedSlots: { customRender: 'customer_name' },
					width: '15%',
				},
				{
					title: 'Solicitud',
					scopedSlots: { customRender: 'description' },
					align: 'center',
					width: '50%',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: '15%',
				},
			],
			modalView: false,
			actualRecord: {},
			selectedRowKeys: [],
		}
	},
	mounted() {
		this.initModule()
		if (!this.customersList.length) {
			this.getCustomers()
		}
	},
	methods: {
		moment,
		numeral,
		copySKU(sku) {
			copy(sku)
			Vue.prototype.$notification.success({
				message: 'Cotizaciones',
				description: `SKU ${sku} copiado`,
			})
		},
		getLogs(id) {
			// console.log('getLogs', id)
			this.$store.dispatch('impressment/GET_LOGS', id).then((response) => {
				this.logs = _.cloneDeep(response.data)
				this.logsView = true
			})
		},
		openEvidenceModal(id) {
			this.evidencesModal = _.cloneDeep(
				utilities.objectValidate(
					this.impressmentList.find((e) => e.id == id),
					['evidences'],
					[],
				),
			)

			this.evidencesModalShow = true
		},
		changeStatus(id, status) {
			// console.log({ id, status })
			let text = ''

			switch (Number(status)) {
				case 0:
					text = `¿Confirmas regresar a editar la solicitud de la cotización #${id}?`
					break
				case 1:
					text = `¿Confirmas iniciar el proceso de preparación de la cotización #${id}?`
					break
				case 2:
					text = `¿Confirmas que la cotización #${id} ya se encuentra lista para reparto?`
					break
				case 3:
					text = `¿Confirmas que el cliente ha recibido su pedido?`
					break
			}

			Swal.fire({
				title: 'Cotizaciones',
				text,
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				confirmButtonColor: '#41b883',
				cancelButtonColor: '#f5222e',
				reverseButtons: true,
			}).then(async (result) => {
				if (result.isConfirmed) {
					//
					let payload = {
						id: id,
						status,
					}
					if (status == 2) {
						this.modalTitle = 'Cotización para reparto'
						this.impressment_id = id
						this.modalQuotationFiles = true

						return false
					}

					if (status == 3) {
						this.modalTitle = 'Entrega de productos'
						this.impressment_id = id
						this.modalQuotationFiles = true

						return false
					}

					await this.$store.dispatch('impressment/UPDATE_STATUS', payload).then(() => {
						this.initModule()
					})
				}
			})
		},
		setTicket(id) {
			// Solicitamos la terminación del ticket de pago
			Swal.fire({
				title: 'Cotizaciones',
				text: 'Ingresa los últimos dígitos del ticket de pago',
				input: 'text',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				confirmButtonColor: '#41b883',
				cancelButtonColor: '#f5222e',
				reverseButtons: true,
				inputAttributes: {
					autocorrect: 'off',
					autocomplete: 'off',
				},
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!value || isNaN(value)) {
							resolve('Ingresa los últimos dígitos del ticket de pago')
						}
						resolve()
					})
				},
			}).then(async (result) => {
				if (result.isConfirmed) {
					//
					let payload = {
						id: id,
						posID: result.value,
					}

					await this.$store.dispatch('impressment/UPDATE_STATUS', payload).then(() => {
						this.initModule()
					})
				}
			})
		},
		searchById() {
			this.$store.commit('impressment/SET_STATE', {
				filters: {
					...this.filters,
					customer_id: '',
					status: '',
				},
			})
			this.initModule()
		},
		getCustomers() {
			let payload = {}
			this.$store.dispatch('customers/GET', payload)
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		handleStatusChange(e) {
			this.$store.commit('impressment/SET_STATE', {
				filters: {
					...this.filters,
					status: e.target.value,
				},
			})
			this.initModule()
		},
		onAddRecord() {
			this.$router.push('/impressment/create')
		},
		initModule(changeStatus = true) {
			if (!changeStatus) {
				this.$store.commit('impressment/SET_STATE', {
					filters: {
						...this.filters,
						id: '',
					},
				})
			}
			if (this.filters.customer_id != '' && this.filters.id != '') {
				this.$store.commit('impressment/SET_STATE', {
					filters: {
						...this.filters,
						id: '',
					},
				})
			}

			this.$store.dispatch('impressment/GET', this.filters)
		},
		onEditRecord(id) {
			this.$router.push(`/impressment/${id}`)
		},
		printQuotation(id) {
			// elegimos el tipo de impresión que necesitamos
			Swal.fire({
				title: 'Selecciona el tipo de impresión',
				input: 'select',
				inputOptions: {
					quote: 'Cotización (con precios)',
					order: 'Pedido (sin precios)',
				},
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'Cancelar',
				inputPlaceholder: 'Selecciona',
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!value) {
							resolve('Es necesario elegir una impresión')
						}
						resolve()
					})
				},
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('impressment/GENERATE_PDF', { id, type: result.value }).then((response) => {
						this.quotationBase64 = `data:application/pdf;base64,${response.data.base64}`
						setTimeout(() => {
							this.modalQuotationView = true
						}, 100)
					})
				}
			})
		},
		closeQuotationModal() {
			this.modalQuotationView = false
			this.quotationBase64 = ''
		},
		closeQuotationFilesModal(reloadStatus = false) {
			this.modalQuotationFiles = false
			if (reloadStatus) {
				this.initModule()
			}
		},
		sendQuotation(id, method) {
			Swal.fire({
				title: 'Atención',
				text: `La cotización será enviada al ${method} del cliente/distribuidor. ¿Deseas continuar?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'Cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					let payload = {
						id,
						method,
					}
					await this.$store.dispatch('impressment/SEND_GENERATE_PDF', payload)
					this.initModule()
				}
			})
		},
		onCancelRecord(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas cancelar la cotización?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No',
			}).then(async (result) => {
				//
				if (result.isConfirmed) {
					await this.$store.dispatch('impressment/DELETE', id)
					this.initModule()
				}
			})
		},
		confirmQuote(id) {
			//
			switch (Number(this.filters.status)) {
				case 5:
					this.modalTitle = 'Autorización un pedido'
					this.impressment_id = id
					break
			}
			this.modalQuotationFiles = true
		},
		closeModal() {
			this.modalView = false
		},
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys
		},
		onSearch(value) {
			if (value != '') {
				this.impressmentListFiltered = []
				value = value.toLowerCase()
				this.impressmentListFiltered = this.impressmentList.filter((obj) =>
					Object.values(obj).some((val) => {
						if (typeof val == 'string' && val.toLowerCase().includes(value)) {
							return true
						}
					}),
				)
			}
		},
	},
}
</script>

<style lang="scss">
.tableImpressmentElements {
	td {
		padding-top: 5px !important;
		padding-bottom: 5px !important;
	}
}
</style>