<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="row">
			<div class="col-md-12">
				<a-upload-dragger name="file" accept="image/*" :before-upload="beforeUpload" :multiple="true" :show-upload-list="false">
					<p class="ant-upload-drag-icon">
						<a-icon type="inbox" />
					</p>
					<p class="ant-upload-text">Haga clic o arrastre el archivo a esta área para cargarlo</p>
					<p class="ant-upload-hint">Admite cargas individuales o en bloque</p>
				</a-upload-dragger>
			</div>
		</div>
	</a-spin>
</template>

<script>
import Swal from 'sweetalert2'

export default {
	name: 'ImpressmentFilesComponent',
	props: {
		id: {
			default: '',
		},
		status: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			spinnerLoaderLabel: 'Actualizando registro',
			spinnerLoader: false,
			files: [],
		}
	},
	methods: {
		beforeUpload(file, fileList) {
			this.files.push(file)

			if (this.files.length == fileList.length) {
				this.uploadFile()
			}
			return false
		},
		async uploadFile() {
			// console.log('uploadFile-->')
			this.spinnerLoader = true

			for (let index = 0; index < this.files.length; index++) {
				const file = this.files[index]
				// console.log('element-->', element)

				let formData = new FormData()
				formData.append('file', file)
				formData.append('id', this.id)
				formData.append('impressment_status', `E_${Number(this.status) == 5 ? 2 : Number(this.status) + 1}`)

				try {
					await this.$store.dispatch('impressment/UPLOAD_FILES', formData)
				} catch (error) {
					console.error('Error al subir el archivo:', error)
				}
			}
			this.spinnerLoader = false

			// Se actualiza status de una cotización
			await this.$store.dispatch('impressment/UPDATE_STATUS', {
				id: this.id,
				status: Number(this.status) == 5 ? 2 : Number(this.status) + 1,
			})

			this.files = []
			this.$emit('close')
		},
	},
}
</script>
